import React, { FunctionComponent } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import 'destyle.css/destyle.css';
import 'utility-opentype/css/utility-opentype.css';

import { Box as Div, Section, Span, Aspect } from 'src/components/abstract/boxes';
import { Stack, Chain } from 'src/components/abstract/stacks';
import { FlowGrid } from 'src/components/abstract/grids';
import { P, Ul, Li, Heading } from 'src/components/abstract/texts';
import { FontAwesomeIcon } from 'src/components/vector/Icon';
// legacy
import { ButtonLink, Button } from 'src/components/legacy/Button';
import { Banner } from 'src/components/legacy/Banner';
// SVG Components
import RasaSquareLogoSVG from 'src/components/vector/RasaSquareLogoSVG';
import l3AiMountainsUrl from 'src/components/vector/l3-ai-mountains.svg';
import l3AiLogoUrl from 'src/components/vector/l3-ai-logo.svg';
import bannerImage from 'src/images/banners/bubbles.png';

const Header = styled(Div).attrs({ forwardedAs: 'header', role: 'banner' })``;
const Footer = styled(Div).attrs({ forwardedAs: 'footer', role: 'contentinfo' })``;
const Main = styled(Div).attrs({ forwardedAs: 'main' })`
  flex: 1 1 auto;
`;
const Page = styled(Div)`
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  & > * {
    flex: 0 1 auto;
    width: stretch;
  }
`;

/*
  TEMPORARY components!

 */
const NoBr = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const Pill = styled(Span)`
  display: inline-block;
  border-radius: 50vmin;
  padding: 0.45em 1.2em;
  background: white;
  color: black;
`;

const NavLink = styled(Link).attrs({
  getProps: ({ isCurrent, isPartiallyCurrent }) => ({
    'data-current': isCurrent,
    'data-partly-current': isPartiallyCurrent,
  }),
})`
  display: inline-block;
  position: relative;
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 4px;
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
  }
  &[data-current='true']::after {
    background-color: currentColor;
  }
  &[data-partly-current='true'] {
  }
`;

const Layout: FunctionComponent = ({ headerSlot, mainSlot, footerSlot }) => {
  return (
    <Page bg="pearl">
      <Header>
        <Banner backgroundImage={bannerImage} centered primary>
          This is our 2020 archive page! Some of the links below are disabled.{' '}
          <a
            style={{ color: 'inherit', textDecoration: 'underline' }}
            href="https://www.youtube.com/playlist?list=PL75e0qA87dlGP51yZ0dyNup-vwu0Rlv86"
            target="_blank"
            rel="nooopener noreferrer"
          >
            Videos of the 2020 talks are here
          </a>
          {', '}
          and{' '}
          <a
            style={{ color: 'inherit', textDecoration: 'underline' }}
            href="https://l3-ai.dev/"
            target="_blank"
            rel="nooopener noreferrer"
          >
            the current website is here!
          </a>
        </Banner>
        <Chain
          as="nav"
          ai="center"
          py={{ _: 20, m: 30 }}
          px={{ _: '1rem', s: '2.5rem' }}
          maxw="wrap-xl"
          mx="auto"
        >
          <Aspect ratio={189 / 36} h="30">
            <img src={l3AiLogoUrl} alt="The Logotype of L3-AI" />
          </Aspect>
          <Div f="sans-xs" fw="bold" ml="auto">
            <NavLink to="/">HOME</NavLink>
          </Div>
          <Div ml={80} f="sans-xs" fw="bold">
            <NavLink to="/schedule/">SCHEDULE</NavLink>
          </Div>
          <Div ml={80} f="sans-xs" fw="bold">
            <NavLink>L3 MERCH</NavLink>
          </Div>
          <Div ml={80} d={{ _: 'none', s: 'block' }}>
            <ButtonLink>
              Enter the event
              <FontAwesomeIcon icon={['far', 'arrow-right']} style={{ marginLeft: 8 }} />
            </ButtonLink>
          </Div>
        </Chain>
        {headerSlot}
      </Header>
      <Main>{mainSlot}</Main>
      <Footer>
        {footerSlot}

        <Section bg="haiti">
          <FlowGrid gapY="m" ta="center" px="frame" pt="l" pb="xl" maxw="wrap-xl" mx="auto">
            <Stack w={{ m: 'span-8' }} mx="auto" f="sans-s">
              <Aspect ratio={177 / 161} h={200}>
                <img
                  src={l3AiMountainsUrl}
                  alt="A path through the mountains with L3-AI at the start and a flag at the summit"
                />
              </Aspect>
              <Heading fz="l">Ready for L3-AI?</Heading>
              <P fw="bold">L3-AI is free for all participants!</P>
              <p>
                <ButtonLink>
                  Enter the event
                  <FontAwesomeIcon icon={['far', 'arrow-right']} style={{ marginLeft: 8 }} />
                </ButtonLink>
              </p>
            </Stack>
          </FlowGrid>
        </Section>
        <Chain
          ai="center"
          f="sans-2xs"
          fw="500"
          py={40}
          px={{ _: '1rem', s: '2.5rem' }}
          maxw="wrap-xl"
          mx="auto"
        >
          <a target="_blank" rel="noreferrer" href="//rasa.com/">
            <RasaSquareLogoSVG h={40} />
          </a>
          <Span ml="auto">
            <a target="_blank" rel="noreferrer" href="//rasa.com/about">
              About Rasa
            </a>
          </Span>
          <Span>
            <a target="_blank" rel="noreferrer" href="//rasa.com/community/code-of-conduct">
              Code of Conduct
            </a>
          </Span>
          <Span>
            <a target="_blank" rel="noreferrer" href="//rasa.com/privacy-policy">
              Privacy Policy
            </a>
          </Span>
          <Span>
            <a target="_blank" rel="noreferrer" href="//rasa.com/imprint">
              Imprint
            </a>
          </Span>
        </Chain>
      </Footer>
    </Page>
  );
};

export default Layout;
