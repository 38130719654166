import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/page.tsx";
import { Content } from 'src/components/legacy/Content';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1>{`Frequently asked Questions`}</h1>
    <Content mdxType="Content">
      <h3>{`Where does Rasa Developer Summit take place?`}</h3>
      <p>{`The event takes place at Gallery 308 in San Francisco. It’s a beautiful industrial event space with a panoramic view over the golden gate bridge in the francisco maritime bay area. The venue address is: Landmark Building A, 2 Marina Blvd, San Francisco, CA 94123, USA`}</p>
      <h3>{`What is the date of the event?`}</h3>
      <p>{`Rasa Developer Summit takes place Tuesday September 24th 2019. Doors open at 9am that day.`}</p>
      <h3>{`Do you have any hotel recommendations?`}</h3>
      <p>{`You can find a list of hotel recommendations close to the area on the website of our venue.`}</p>
      <h3>{`What language are the talks in?`}</h3>
      <p>{`All presentations are given in English.`}</p>
      <h3>{`Can I present my own project and learnings?`}</h3>
      <p>{`This years call for proposal has already ended.`}</p>
      <h3>{`What tickets are available?`}</h3>
      <p>{`There are two kinds of tickets available. General Admission tickets and student tickets which are available to students and researchers from universities. You can get tickets here. If you are a Rasa contributor, you get free access to Rasa Developer Summit. If you are a contributor and haven’t received more information please send us a message.`}</p>
      <h3>{`Can I attend online via a livestream?`}</h3>
      <p>{`There is no live stream but the talks are recorded and shared with our community after the event. Sign up for our newsletter to get updated.`}</p>
      <h3>{`Do you have a code of conduct?`}</h3>
      <p>{`Yes, you can find our code of conduct here.`}</p>
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      