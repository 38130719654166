import React, { forwardRef } from 'react';
import intersection from 'lodash/intersection';
import { css } from 'styled-components';

// TRICK: https://ultimatecourses.com/blog/understanding-javascript-types-and-reliable-type-checking#true-object-types
export const classof = (value) => Object.prototype.toString.call(value).slice(8, -1);

export const isString = (value) => classof(value) === 'String';
export const isDate = (value) => classof(value) === 'Date';
export const isRegExp = (value) => classof(value) === 'RegExp';
export const isNumber = (value) => classof(value) === 'Number' && !Number.isNaN(value);
export const isNaN = (value) => Number.isNaN(value);
export const isInteger = (value) => Number.isInteger(value);
export const isBoolean = (value) => classof(value) === 'Boolean';
export const isNull = (value) => classof(value) === 'Null';
export const isUndefined = (value) => classof(value) === 'Undefined';
export const isFunction = (value) => classof(value) === 'Function';
export const isArray = (value) => classof(value) === 'Array';
export const isObject = (value) => classof(value) === 'Object';
export const isSymbol = (value) => classof(value) === 'Symbol';
export const isMap = (value) => classof(value) === 'Map';
export const isSet = (value) => classof(value) === 'Set';
export const isWeakMap = (value) => classof(value) === 'WeakMap';
export const isWeakSet = (value) => classof(value) === 'WeakSet';

export function flattenThemeValues(target) {
  const keyPaths = [];
  function walk(object, previousKey) {
    Object.keys(object).forEach(function (key) {
      const value = object[key];
      const currentKey = previousKey ? `${previousKey}.${key}` : key;
      if (isObject(value) && !('_' in value)) return walk(value, currentKey);
      else keyPaths.push(currentKey);
    });
  }
  walk(target);
  return keyPaths;
}

export function parseTheme(theme) {
  const { breakpoints, fontSpecs } = theme;
  const bpKeysAsc = ['_'].concat(
    Object.keys(breakpoints).sort((a, b) => (breakpoints[a] > breakpoints[b] ? 1 : -1)),
  );
  const bpKeysDesc = bpKeysAsc.slice().reverse();
  const valuePaths = flattenThemeValues(theme);
  const fontAlias = Object.keys(fontSpecs)[0];
  return {
    ...theme,
    bpKeysAsc,
    bpKeysDesc,
    valuePaths,
    current: { fontAlias, hLevel: 1 },
  };
}

export function parseValue(n) {
  const type = typeof n;
  if (!['string', 'number'].includes(type)) {
    throw Error(`invalid type for CSS value: ${type}`);
  }
  n = type === 'string' ? n.trim() : n;
  const value = parseFloat(n);
  if (Number.isNaN(value)) return { value: n };
  const unit = type === 'string' ? n.replace(value, '') : '';
  return { value, unit };
}

export function modifyValue(n, fn) {
  n = typeof n === 'string' ? n.trim() : n;
  const number = parseFloat(n);
  if (Number.isNaN(number)) return n;
  const unit = String(n).replace(number, '');
  return `${fn ? fn(number) : number}${unit || 'px'}`;
}

export function assertUnit(n, unit = 'px') {
  n = typeof n === 'string' ? n.trim() : n;
  const number = parseFloat(n);
  if (Number.isNaN(number)) return n;
  return `${number}${unit}`;
}

export function queryString(query, breakpoints) {
  const [lo, hi] = [].concat(query);
  const loValue = lo && breakpoints[lo];
  const hiValue = hi && breakpoints[hi];
  const loQuery = loValue ? ` and (min-width: ${loValue}em)` : '';
  const hiQuery = hiValue ? ` and (max-width: ${hiValue - 0.01}em)` : '';
  return loQuery || hiQuery ? `@media screen${loQuery}${hiQuery}` : '';
}

export const media = (query, expr) => (props) => {
  const mediaQuery = queryString(query, props.theme.breakpoints);
  return [`${mediaQuery} {`, expr, `}`];
};

/* eslint-disable prettier/prettier */

export const propTemplates = {
  ['font-size'](n) { return css`--font-size: ${n};`; },
  ['line-height'](n) { return css`--line-height: ${n};`; },
  ['row-gap'](n) { return css`--row-gap: ${n};`; },
  ['column-gap'](n) { return css`--column-gap: ${n};`; },
  ['column-count'](n) { return css`--column-count: ${n};`; },
  ['column-span'](n) {
    return css`
      width: calc((99.99999% + var(--column-gap)) * var(--column-span) / var(--column-count) - var(--column-gap));
      .McolEl-base > & { width: initial; }
      --column-span: ${n};
      .FlowGridEl-base > & > * { --column-count: ${n}; }
    `;
  },
  ['column-push-left'](n) {
    return css`
      margin-left: calc((99.99999% + var(--column-gap)) * ${n} / var(--column-count));
      .FlowGridEl-base > & { margin-left: calc(99.99999% * ${n} / var(--column-count)); }
    `;
  },
  ['column-push-right'](n) {
    return css`
      margin-right: calc((99.99999% + var(--column-gap)) * ${n} / var(--column-count));
      .FlowGridEl-base > & { margin-right: calc(99.99999% * ${n} / var(--column-count)); }
    `;
  },

  ['width'](n) { return css`width: ${n} !important;` },
  ['min-width'](n) { return css`min-width: ${n} !important;` },
  ['max-width'](n) { return css`max-width: ${n} !important;` },

  ['height'](n) { return css`height: ${n};` },
  ['min-height'](n) { return css`min-height: ${n};` },
  ['max height'](n) { return css`max-height: ${n};` },

  ['margin-top'](n) { return css`margin-top: ${n} !important;` },
  ['margin-right'](n) { return css`margin-right: ${n} !important;` },
  ['margin-bottom'](n) { return css`margin-bottom: ${n} !important;` },
  ['margin-left'](n) { return css`margin-left: ${n} !important;` },
  ['margin-y'](t, b=t) { return css`margin-top: ${t} !important; margin-bottom: ${b} !important;` },
  ['margin-x'](r, l=r) { return css`margin-right: ${r} !important; margin-left: ${l} !important;` },
  ['padding-top'](n) { return css`padding-top: ${n} !important;` },
  ['padding-right'](n) { return css`padding-right: ${n} !important;` },
  ['padding-bottom'](n) { return css`padding-bottom: ${n} !important;` },
  ['padding-left'](n) { return css`padding-left: ${n} !important;` },
  ['padding-y'](t, b=t) { return css`padding-top: ${t} !important; padding-bottom: ${b} !important;` },
  ['padding-x'](r, l=r) { return css`padding-right: ${r} !important; padding-left: ${l} !important;` },

  ['display'](n) { return css`display: ${n} !important;` },
  ['position'](n) { return css`position: ${n} !important;` },

  ['flex'](n) { return css`flex: ${n} !important;` },
  ['flex-direction'](n) { return css`flex-direction: ${n} !important;` },
  ['text-align'](n) { return css`text-align: ${n} !important;` },
  ['align-items'](n) { return css`align-items: ${n} !important;` },
  ['align-content'](n) { return css`align-content: ${n} !important;` },
  ['justify-content'](n) { return css`justify-content: ${n} !important;` },
  ['order'](n) { return css`order: ${n} !important;` },

  ['font-style'](n) { return css`font-style: ${n};` },
  ['font-weight'](n) { return css`font-weight: ${n};` },
  ['white-space'](n) { return css`white-space: ${n};` },
  ['pointer-events'](n) { return css`pointer-events: ${n};` },
  ['user-select'](n) { return css`user-select: ${n};` },
  ['cursor'](n) { return css`cursor: ${n};` },

  // basic colors
  ['color'](n) { return css`color: ${n};` },
  ['border-color'](n) { return css`border-color: ${n};` },
  ['outline-color'](n) { return css`outline-color: ${n};` },
  ['background-color'](n) { return css`background-color: ${n};` },

};

/* eslint-enable prettier/prettier */

export const propOutputFns = {
  // display(key, valueTemplate, ruleTemplate) {
  //   // if (key.startsWith('span')) {
  //   //   const [, n = 1] = key.split('-');
  //   //   return propTemplates['column-span'](n);
  //   // } else {
  //   //   return ruleTemplate(valueTemplate(key));
  //   // }
  // },
  // position(key, valueTemplate, ruleTemplate) {
  //   // if (key.startsWith('span')) {
  //   //   const [, n = 1] = key.split('-');
  //   //   return propTemplates['column-span'](n);
  //   // } else {
  //   //   return ruleTemplate(valueTemplate(key));
  //   // }
  // },
  width(key, valueTemplate, ruleTemplate) {
    if (key.startsWith('span')) {
      const [, n = 1] = key.split('-');
      return propTemplates['column-span'](n);
    } else {
      return ruleTemplate(valueTemplate(key));
    }
  },
  ['max-width'](key, valueTemplate, ruleTemplate) {
    if (key.startsWith('wrap')) {
      let [, bp] = key.split('-');
      return bp
        ? (props, query) => {
            if (bp === 'last') bp = props.theme.bpKeysDesc[0];
            return media(bp, ruleTemplate(`var(--outer-width-${bp})`))(props, query);
          }
        : ruleTemplate(`var(--outer-width)`);
    } else {
      return ruleTemplate(valueTemplate(key));
    }
  },
  margin(subProp, key, valueTemplate, ruleTemplate, themeValuePaths) {
    let isNeg = key.startsWith('neg-');
    key = isNeg ? key.slice(4) : key;
    switch (key) {
      case 'bleed': {
        const dirs = { x: ['right', 'left'], y: ['top', 'bottom'] }[subProp] || [subProp];
        const axis = {
          x: 'width',
          y: 'height',
          top: 'height',
          left: 'width',
          bottom: 'height',
          right: 'width',
        }[subProp];
        return [`${axis}: stretch;`, ruleTemplate(...dirs.map((dir) => `calc(var(--outer-${dir}) / -1)`))];
      }
      default: {
        const dir = { top: 'y', right: 'x', bottom: 'y', left: 'x' }[subProp] || subProp;
        if (dir === 'x' && ['push', 'pull'].some((s) => key.startsWith(s))) {
          const [move, n = 1] = key.split('-');
          isNeg = move === 'pull' ? !isNeg : isNeg;
          return propTemplates[`column-push-${subProp}`](isNeg ? n / -1 : n);
        }
        const isInnerKey = themeValuePaths.includes(`spacings.inner.x.${key}`);
        return isInnerKey
          ? ruleTemplate(`calc(var(--inner-${dir}-${key}) / ${isNeg ? -1 : 1})`)
          : ruleTemplate(valueTemplate(key));
      }
    }
  },
  padding(subProp, key, valueTemplate, ruleTemplate, themeValuePaths) {
    switch (key) {
      case 'frame': {
        const dirs = { x: ['right', 'left'], y: ['top', 'bottom'] }[subProp] || [subProp];
        return ruleTemplate(...dirs.map((dir) => `var(--outer-${dir})`));
      }
      default: {
        const dir = { top: 'y', right: 'x', bottom: 'y', left: 'x' }[subProp] || subProp;
        const isInnerKey = themeValuePaths.includes(`spacings.inner.${dir}.${key}`);
        return isInnerKey ? ruleTemplate(`var(--inner-${dir}-${key})`) : ruleTemplate(valueTemplate(key));
      }
    }
  },
  gap(propVariant, key, valueTemplate, ruleTemplate, themeValuePaths) {
    const dir = { row: 'y', column: 'x' }[propVariant];
    const isInnerKey = themeValuePaths.includes(`spacings.inner.${dir}.${key}`);
    return isInnerKey ? ruleTemplate(`var(--inner-${dir}-${key})`) : ruleTemplate(valueTemplate(key));
  },
};

['column', 'row'].forEach(
  (dir) => (propOutputFns[`${dir}-gap`] = propOutputFns.gap.bind(propOutputFns, dir)),
);

['top', 'right', 'bottom', 'left', 'x', 'y'].forEach((dir) => {
  propOutputFns[`margin-${dir}`] = propOutputFns.margin.bind(propOutputFns, dir);
  propOutputFns[`padding-${dir}`] = propOutputFns.padding.bind(propOutputFns, dir);
});

/*
  OUTPUT TYPES
  dimensional: margin, padding, gap, width, height
    CSS dimension
    (CSS keyword)
    SYS keyword
    SYS dimension
    RAW output
  numeric: span, cols,
    SYS keyword
    number
    RAW output
  keyword: alignment,
    SYS keyword
    RAW output

 */

export function createDimensionalOutput(propName) {
  const propTemplate = propTemplates[propName];
  const propOutputFn = propOutputFns[propName];
  const baseOutputFn = (cssValue, valueTemplate = (n) => n, themeValuePaths) => {
    const { value, unit } = parseValue(cssValue); // validates and trims !
    if (isNumber(value)) {
      return propTemplate(valueTemplate(`${value}${unit || 'px'}`));
    } else {
      return propOutputFn
        ? propOutputFn(value, valueTemplate, propTemplate, themeValuePaths)
        : propTemplate(valueTemplate(cssValue));
    }
  };
  return (arg, valueTemplate) => (props) => {
    if (!isObject(arg)) return baseOutputFn(arg, valueTemplate, props.theme.valuePaths);
    const argBreakpoints = intersection(props.theme.bpKeysAsc, Object.keys(arg));
    return argBreakpoints.map((_, i, arr) => {
      const bpLo = arr[i],
        bpHi = arr[i + 1];
      return media([bpLo, bpHi], baseOutputFn(arg[bpLo], valueTemplate, props.theme.valuePaths))(props);
    });
  };
}

export function createNumericOutput(propName) {
  const propTemplate = propTemplates[propName];
  const propOutputFn = propOutputFns[propName];
  const baseOutputFn = (value, valueTemplate = (n) => n, themeValuePaths) => {
    if (isNumber(value)) {
      return propTemplate(valueTemplate(value));
    } else {
      return propOutputFn
        ? propOutputFn(value, valueTemplate, propTemplate, themeValuePaths)
        : propTemplate(valueTemplate(value));
    }
  };
  return (arg, valueTemplate) => (props) => {
    if (!isObject(arg)) return baseOutputFn(arg, valueTemplate, props.theme.valuePaths);
    const argBreakpoints = intersection(props.theme.bpKeysAsc, Object.keys(arg));
    return argBreakpoints.map((_, i, arr) => {
      const bpLo = arr[i],
        bpHi = arr[i + 1];
      return media([bpLo, bpHi], baseOutputFn(arg[bpLo], valueTemplate, props.theme.valuePaths))(props);
    });
  };
}

export function createColorOutput(propName) {
  const propTemplate = propTemplates[propName];
  return (arg, valueTemplate = (n) => n) => (props) => {
    if (!isObject(arg)) return propTemplate(valueTemplate(props.theme.colors[arg] || arg));
    const argBreakpoints = intersection(props.theme.bpKeysAsc, Object.keys(arg));
    return argBreakpoints.map((_, i, arr) => {
      const bpLo = arr[i],
        bpHi = arr[i + 1];
      return media([bpLo, bpHi], propTemplate(valueTemplate(props.theme.colors[arg] || arg)))(props);
    });
  };
}

export const outputFunctions = {
  // DIMENSIONAL PROPS
  w: createDimensionalOutput('width'),
  minw: createDimensionalOutput('min-width', 'width'),
  maxw: createDimensionalOutput('max-width', 'width'),
  h: createDimensionalOutput('height'),
  minh: createDimensionalOutput('min-height', 'height'),
  maxh: createDimensionalOutput('max-height', 'height'),
  my: createDimensionalOutput('margin-y', 'margin'),
  mx: createDimensionalOutput('margin-x', 'margin'),
  mt: createDimensionalOutput('margin-top', 'margin'),
  mr: createDimensionalOutput('margin-right', 'margin'),
  mb: createDimensionalOutput('margin-bottom', 'margin'),
  ml: createDimensionalOutput('margin-left', 'margin'),
  py: createDimensionalOutput('padding-y', 'padding'),
  px: createDimensionalOutput('padding-x', 'padding'),
  pt: createDimensionalOutput('padding-top', 'padding'),
  pr: createDimensionalOutput('padding-right', 'padding'),
  pb: createDimensionalOutput('padding-bottom', 'padding'),
  pl: createDimensionalOutput('padding-left', 'padding'),
  gapX: createDimensionalOutput('column-gap'),
  gapY: createDimensionalOutput('row-gap'),

  // NUMERIC PROPS
  d: createNumericOutput('display'),
  pos: createNumericOutput('position'),
  cols: createNumericOutput('column-count'),
  span: createNumericOutput('column-span'),
  fz: createNumericOutput('font-size'),
  lh: createNumericOutput('line-height'),

  fx: createNumericOutput('flex'),
  fd: createNumericOutput('flex-direction'),
  ta: createNumericOutput('text-align'),
  ai: createNumericOutput('align-items'),
  ac: createNumericOutput('align-content'),
  jc: createNumericOutput('justify-content'),
  order: createNumericOutput('order'),

  fs: createNumericOutput('font-style'),
  fw: createNumericOutput('font-weight'),
  ws: createNumericOutput('white-space'),
  pe: createNumericOutput('pointer-events'),
  us: createNumericOutput('user-select'),
  cur: createNumericOutput('cursor'),

  /*
  SCHEME PROPS
    op
    fgc
    fgo
    bgc
    bgo
    bdc
    bdo
    bdw
  */
  fg: createColorOutput('color'),
  bd: createColorOutput('border-color'),
  ol: createColorOutput('outline-color'),
  bg: createColorOutput('background-color'),
};

export function variants(Component, propVariants) {
  function outerFn({ v, ...rest }, ref) {
    return <Component forwardedRef={ref} {...{ ...propVariants[v], ...rest }} />;
  }
  outerFn.displayName = Component.displayName || Component.name;
  return forwardRef(outerFn);
}
