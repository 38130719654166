import React from 'react';
import styled, { css } from 'styled-components';
import tinycolor from 'tinycolor2';

import { Box } from 'src/components/abstract/boxes';

interface PhProps {
  bg?: string;
  h?: number;
  aspect?: number;
}

interface PhOuterProps extends PhProps {
  txt?: string;
  children?: React.ReactChildren;
}

const PhOuter = styled.div.withConfig({
  shouldForwardProp: (property) => !'bg,h,aspect'.split(',').includes(property),
})<PhProps>`
  ${({ bg, h, aspect }) => css`
    position: relative;
    color: ${bg && tinycolor(bg).isDark() ? 'white' : 'black'};
    background-color: ${bg || 'rgba(0,0,0,0.1)'};
    ${h ? 'height' : 'padding-bottom'}: ${h ? `${h}px` : `${100 / (aspect || 1)}%`};
  `}
`;

const PhInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PhText = styled.div`
  font-size: 14px;
  text-align: center;
`;

export const Ph = ({ bg, h, aspect, txt = 'placeholder', children, ...restProps }: PhOuterProps) => (
  <Box {...restProps}>
    <PhOuter {...{ bg, h, aspect }}>
      <PhInner>{children ? children : <PhText>{txt}</PhText>}</PhInner>
    </PhOuter>
  </Box>
);

export function Dump({ data }) {
  return (
    <pre style={{ whiteSpace: 'pre' }}>
      <code>{JSON.stringify(data, null, 2)}</code>
    </pre>
  );
}
