import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

export const Button = styled(({ type, ...props }) =>
  type === 'button' ? <button type="type" {...props} /> : <Link {...props} />,
)`
  font-size: var(--font-size-sans-s);
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;
  height: 50px;
  padding: 0 1rem;
  -webkit-appearance: none;
  color: white;
  background: ${({ theme }) => theme.secondaryColor};
`;

export const ButtonLink = styled.a`
  font-size: var(--font-size-sans-s);
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;
  height: 50px;
  padding: 0 1rem;
  -webkit-appearance: none;
  color: white;
  background: ${({ theme }) => theme.secondaryColor};
`;

export const GhostButtonLink = styled.a`
  font-size: var(--font-size-sans-s);
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  text-decoration: none !important;
  border: 3px solid white;
  height: 50px;
  padding: 0 1rem;
  -webkit-appearance: none;
  color: white;
`;
