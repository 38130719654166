export { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { config, library } from '@fortawesome/fontawesome-svg-core';
import '@fortawesome/fontawesome-svg-core/styles.css';
config.autoAddCss = false;
import { faArrowRight, faCalendar } from '@fortawesome/pro-regular-svg-icons';
import { faPresentation, faCommentsAlt, faFireplace, faBolt } from '@fortawesome/pro-solid-svg-icons';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';

library.add(
  faArrowRight,
  faTwitter,
  faLinkedin,
  faCalendar,
  faPresentation,
  faCommentsAlt,
  faFireplace,
  faBolt,
);
