// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-mdx": () => import("./../src/pages/404.mdx" /* webpackChunkName: "component---src-pages-404-mdx" */),
  "component---src-pages-faq-mdx": () => import("./../src/pages/faq.mdx" /* webpackChunkName: "component---src-pages-faq-mdx" */),
  "component---src-pages-index-jsx": () => import("./../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-schedule-jsx": () => import("./../src/pages/schedule.jsx" /* webpackChunkName: "component---src-pages-schedule-jsx" */)
}

