import React from 'react';

import { Aspect } from '../abstract/boxes';

function RasaSquareLogoSVG(props) {
  return (
    <Aspect ratio={386 / 470} {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 386 470">
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M326 90.1v-30H201.7V185h29.8v-37.6h64.7V185H326V90v.1zm-29.8 27.5h-64.7V90h64.7v27.5zM181.8 276.2v56.2H60v-30h92V285H60v-77.5h121.8v30h-92V255h92v21.2zM326 237.4v-30H201.7v124.9h29.8v-37.6h64.7v37.5H326v-94.9.1zM296.2 265h-64.7v-27.5h64.7V265z"
          clipRule="evenodd"
        ></path>
        <path
          fill="#fff"
          d="M353.3 32.8V359h-51.5v55.6L208.6 363l-7.3-4H32.7V32.8h320.7zM385.7.3H.3v391.2H193l141 78.3v-78.3h51.6V.3h.1z"
        ></path>
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M141.8 137.5l38.2-14 1.8-.7V60.2H60v2.5l-.3 122.4h29.8v-28.5l22.4-8.2 33.6 36.7h36.8l-40.5-47.6zm-52.2-12.8V90.2H152v11.7l-62.4 22.8z"
          clipRule="evenodd"
        ></path>
      </svg>
    </Aspect>
  );
}

export default RasaSquareLogoSVG;
