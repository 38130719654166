/* eslint-disable mdx/no-unused-expressions */
import get from 'lodash/get';
import { css } from 'styled-components';

import { modifyValue, assertUnit, media } from './system';

/* eslint-disable prettier/prettier */

export const rootPropOutputs = ({ theme }) => {

      const spacings = theme.valuePaths.reduce((arr, path) => {
        const subPath = path.match(/^spacings\.(.+)/)?.[1];
        if (subPath) arr.push(subPath);
        return arr;
      }, []);

      const fontSizes = theme.valuePaths.reduce((arr, path) => {
        const subPath = path.match(/^fontSizes\.(.+)/)?.[1];
        if (subPath) arr.push(subPath);
        return arr;
      }, []);

      // const fontSpecs = theme.valuePaths.reduce((arr, path) => {
      //   const subPath = path.match(/^fontSpecs\.(.+)/)?.[1];
      //   if (subPath) arr.push(subPath);
      //   return arr;
      // }, []);

      return theme.bpKeysAsc.map((key) => media(key, css`
        ${[].concat(
          /*
            STATIC PROPERTIES:
              outer-width-[bp]
              font-size-[fa]-[fz]
              line-height-[fa]-[fz]
          */
          key === '_' &&
            theme.bpKeysAsc.reduce((arr, bp) => {
              if (bp !== '_')
                arr.push(`--outer-width-${bp}: ${assertUnit(theme.breakpoints[bp], 'rem')};`);
              return arr;
            }, []),
          // key === '_' &&
          //   fontSpecs.map((path) => {
          //     const [fontAlias, fontProp] = path.split('.');
          //     return `--${kebabCase(fontProp)}-${kebabCase(fontAlias)}: ${theme.fontSpecs[fontAlias][fontProp]};`;
          //   }),

          /*
            RESPONSIVE PROPERTIES:
              outer-width
              grid-columns
              inner-x-[x], inner-y-[y], outer-[dir]
              font-size-[fa]-[fz], line-height-[fa]-[fz]
          */
          `--outer-width: ${theme.breakpoints[key] ? assertUnit(theme.breakpoints[key], 'em') : `100%`};`,
          theme.gridColumns[key] && `--column-count: ${theme.gridColumns[key]};`,
          spacings.reduce((arr, path) => {
            const value = get(theme, `spacings.${path}.${key}`) || (key === '_' && get(theme, `spacings.${path}`));
            if (value) arr.push(`--${path.replace(/\./g, '-')}: ${modifyValue(String(value))};`);
            return arr;
          }, []),
          fontSizes.reduce((arr, path) => {
            const [fz, lh] = [].concat(get(theme, `fontSizes.${path}.${key}`) || (key === '_' && get(theme, `fontSizes.${path}`)));
            if (fz) arr.push(`--font-size-${path.replace(/\./g, '-')}: ${assertUnit(fz)};`);
            if (lh) arr.push(`--line-height-${path.replace(/\./g, '-')}: ${assertUnit(lh, 'em')};`);
            return arr;
          }, []),
        )
        .filter((n) => n)
        .join('\n')}
      `))

    }

export const rootStyles = css`

  :root {

    ${rootPropOutputs}

    /* set defaults */
    --row-gap: var(--inner-y-m);
    --column-gap: var(--inner-x-m);
    --stack-gap: var(--inner-y-m);
    --chain-gap: var(--inner-x-m);
    --inner-y: var(--inner-y-m);
    --inner-x: var(--inner-x-m);

  }

  /*
    CUSTOM RESET:
    1. old version of overflow-wrap
    2. unify font rendering
    3. unify font rendering
    4. faster, stabler than optimizeLegibility
  */

  ${'@-ms-viewport { width: device-width; }'}

  *, ::before, ::after {

    word-break: keep-all;
    /* word-wrap: anywhere; ms version of following */
    overflow-wrap: break-word;
    /* white-space: initial; */
    hyphens: manual;

    font-family: inherit;
    font-weight: inherit;
    font-style: inherit;
    font-size: inherit;
  }

  :root {
    word-break: normal;
    word-wrap: break-word; /* 1 */
    overflow-wrap: break-word;
    hyphens: auto;

    -moz-osx-font-smoothing: grayscale; /* 2 */
    -webkit-font-smoothing: antialiased; /* 3 */
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    direction: ltr;
    font-family: sans-serif;
    font-kerning: normal;
    font-size: 100%;
    font-style: normal;
    font-weight: normal;
    line-height: 1.25;
    text-align: left;
    text-rendering: auto; /* 4 */
  }

  /* inline text tags */
  abbr, acronym, b, bdo, big, br, cite, code, dfn, em, i, kbd, q, samp, small, span, strong, sub, sup, time, tt, var {
    display: inline;
    vertical-align: baseline;
    /* white-space: normal; */
  }

  strong, b, h1, h2, h3, h4, h5, h6 { font-weight: 700; }
  em, i { font-style: italic; }

  /* UI ELEMENTS */
  /* a, button, datalist, fieldset, form, input, keygen, label, legend, meter, optgroup, option, output, progress, select, textarea */

  a {
    background-color: transparent; /* Remove the gray background on active links in IE 10. */
    -webkit-text-decoration-skip: objects; /* Remove gaps in links underline in iOS 8+ and Safari 8+. */
    &:active, &:hover { outline-width: 0; } /* Remove the outline on focused links when they are also active or hovered */
  }

  /* suppress outline on non-keyboard-focusable elements */
  [tabindex="-1"]:focus {
    outline: 0 !important;
  }

  form, fieldset { display: block; }

  legend {
    box-sizing: border-box; /* 1 */
    color: inherit; /* 2 */
    display: table; /* 1 */
    max-width: 100%; /* 1 */
    padding: 0; /* 3 */
    white-space: normal; /* 1 */
  }

  /* EMBEDDED CONTENT */
  /* applet, audio, canvas, embed, iframe, img, map, noembed, object, picture, svg, video, source, area, track, param */

  applet,
  audio,
  canvas,
  embed,
  iframe,
  img,
  map,
  noembed,
  object,
  picture,
  svg,
  video {
    display: block;
    max-width: 100%;
    height: auto;
    border-style: none;
    border: 0;

    /* do not capture pointer events when inside clickable elements */
    a &,
    button &,
    label & {
      pointer-events: none;
    }
  }

  svg, symbol {
    overflow: visible;
  }

  audio:not([controls]) {
    display: none;
    height: 0;
  }

  iframe {
    border: 0;
  }

  /* MISC */

  blockquote, q {
    quotes: none; /* remove quotes */
    &:before, &:after { /* remove pseudo element styles */
      content: '';
      content: none;
    }
  }

  pre {
    overflow: auto; /* Don't allow content to break outside */
    -ms-overflow-style: scrollbar; /* corresponds to ms-viewport rule */
  }

  /* TABLES */
  /* table, caption, col, colgroup, tbody, td, th, thead, tr, tfoot */

  table {
    display: block;
    width: 100%;
    border: 0; /* strongest way of overriding */
    table-layout: fixed; /* faster, more predictable */
    border-collapse: collapse;
    border-spacing: 0;
  }

  caption {
    text-align: inherit;
    caption-side: bottom;
  }

  th {
    text-align: inherit;
  }

`;

/* eslint-enable prettier/prettier */
