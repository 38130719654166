/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect, forwardRef } from 'react';
import styled, { css } from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import sortBy from 'lodash/sortBy';
import padEnd from 'lodash/padEnd';
import times from 'lodash/times';
// import { nanoid } from 'nanoid';
import Ellipsis from '@moxy/react-ellipsis';
import jstz from 'jstz';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css'; // optional
import 'tippy.js/animations/shift-away.css';

import { FontAwesomeIcon } from 'src/components/vector/Icon';
import { Ph, Dump } from 'src/components/abstract/utils';
import { Stack, Chain } from 'src/components/abstract/stacks';
import { FlowGrid, Flow } from 'src/components/abstract/grids';
import { P, Ul, Li, Heading } from 'src/components/abstract/texts';
import { Box as Div, Section, Span, Aspect } from 'src/components/abstract/boxes';
import Layout from 'src/layouts/test';

/* NOTES re TIMEZONE SUPPORT
  - we are using Intl.DateTimeFormat().formatToParts()
    https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/DateTimeFormat/formatToParts
    https://devhints.io/wip/intl-datetime
  - we are not polyfilling support for the .formatToParts(), but could
    https://github.com/formatjs/date-time-format-timezone
  - we are using jstz, which is a fork of jstimezonedetect, to get the timeZoneName
    https://github.com/iansinnott/jstz
    https://github.com/pellepim/jstimezonedetect
 */

function dateToParts(date, timeZone = 'UTC') {
  return new Intl.DateTimeFormat('en-US', {
    timeZone,
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
    timeZoneName: 'short',
    hour12: false,
  })
    .formatToParts(date)
    .reduce((obj, { type, value }) => {
      obj[type] = value;
      return obj;
    }, {});
}

const formatIcons = {
  Presentation: 'presentation',
  Panel: 'comments-alt',
  Fireside: 'fireplace',
  Lightning: 'bolt',
};

const themeColors = {
  '1 - Intro & Keynotes': '#E622BD',
  '2 - Zero to PoC': '#5A17EE',
  '3 - PoC to Production': '#FB8F10',
  '4 - Post Production & Scaling AI Assistants': '#01A78F',
  '5 - Tools for Conversational AI': '#FA2A58',
};

const TimelineEl = styled(Div)`
  width: auto;
  flex: 0 0 auto !important;
  display: grid;
  grid-gap: 20px var(--inner-x-m);
  margin-right: var(--inner-x-m);
  grid-auto-flow: row dense;
  grid-auto-rows: 60px;
  z-index: -1;
`;

const MarkerEl = styled(Div)`
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100vw - var(--outer-left) - var(--outer-right));
    max-width: calc(var(--outer-width-xl) - var(--outer-left) - var(--outer-right));
    border-top: 1px solid rgba(255, 255, 255, 0.25);
    transform: translateY(-11px);
  }
  &:nth-child(4n + 1)::before {
    border-width: 2px;
  }
`;

function Marker({ hour, minute, timeZone, ...rest }) {
  const date = new Date(Date.UTC(2020, 5, 18, hour, minute));
  const { hour: tzHour, timeZoneName: tzName } = dateToParts(date, timeZone);
  return (
    <MarkerEl fg="electric" ta="right" {...rest}>
      {minute == '00' ? `${tzName} ${tzHour}:${minute}` : `:${minute}`}
    </MarkerEl>
  );
}
const ScheduleEl = styled(Div)`
  display: grid;
  grid-gap: 20px var(--inner-x-m);
  grid-auto-flow: row dense;
  grid-auto-rows: 60px;
  grid-auto-columns: minmax(20%, auto);
  z-index: 0;
`;

const EventEl = styled(Div)`
  background-color: pink;
  ${({ row_start, row_span, col_start, Theme }) => css`
    background-color: ${themeColors[Theme]};
    grid-row-start: ${row_start};
    grid-row-end: span ${row_span};
  `}
`;

const Outer = styled(Div)`
  overflow-y: visible;
  overflow-x: auto;
  display: flex;
  flex-direction: row;
  z-index: 0;
  position: relative;
  & > * {
    flex: 1 1 0%;
  }
`;

const Badge = styled(FontAwesomeIcon)`
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const SpeakerList = styled(Ul)`
  > li {
    display: inline-block;
    white-space: nowrap;
  }
`;

function Event(props) {
  const { timeZone, time } = props;
  const { hour, minute, timeZoneName } = dateToParts(new Date(time), timeZone);
  const { hour: endHour, minute: endMinute } = dateToParts(
    new Date(time + parseInt(props.Duration) * 60 * 1000),
    timeZone,
  );
  const tippyContent = (
    <Stack f="sans-xs" gap={20} px={10} pt={10} pb={20} h="100%">
      <P f="mono-2xs" fg="electric">
        <span>{timeZoneName}</span>{' '}
        <span>
          {hour}:{padEnd(minute, 2, '0')}
        </span>
        {' – '}
        <span>
          {endHour}:{padEnd(endMinute, 2, '0')}
        </span>{' '}
      </P>
      <P fw="bold">{props.Session_Name}</P>
      {props.Speakers?.length > 0 && (
        <SpeakerList fz="2xs">
          {props.Speakers.map(({ data }, i) => (
            <li key={i}>
              {data.Name}
              {i < props.Speakers.length - 1 && <span>,&ensp;</span>}
            </li>
          ))}
        </SpeakerList>
      )}
    </Stack>
  );
  return (
    <Tippy content={tippyContent} animation="shift-away">
      <EventEl {...props} f="sans" fz={{ _: 14, ml: 15 }} fw="500" style={{ position: 'relative' }}>
        <Stack px={10} py={10} gap={20} h="100%">
          <P h="100%">
            <Ellipsis tag="span" style={{ height: '100%', display: 'block', width: '100%' }}>
              {props.Session_Name}
            </Ellipsis>
          </P>
        </Stack>
        {props.Format && <Badge icon={['fas', formatIcons[props.Format]]} style={{ marginLeft: 8 }} />}
      </EventEl>
    </Tippy>
  );
}

// const Toggles = () => {
//   return (
//     <form action="">
//       <fieldset>
//         <legend>Themes</legend>
//         {Object.keys(themeColors).map((name) => {
//           const inputID = nanoid();
//           return (
//             <div key={name}>
//               <label htmlFor={inputID}>
//                 <input type="checkbox" name="themes[]" id={inputID} />
//                 {name.slice(4)}
//               </label>
//             </div>
//           );
//         })}
//       </fieldset>
//     </form>
//   );
// };

function Schedule({ events }) {
  const [timeZone, setTimeZone] = useState('UTC');
  useEffect(() => {
    const tz = jstz.determine().name();
    setTimeZone(tz);
  }, [timeZone]);
  const { hour: tzHour, timeZoneName: tzName } = dateToParts(
    new Date(Date.UTC(2020, 5, 18, 11, 0)),
    timeZone,
  );
  return (
    <Stack gap="xl" py="xl" px="frame" maxw="wrap-xl" mx="auto">
      <Section mx="auto">
        <Stack gap="l" ta="center" span={{ ml: 8 }} mx="auto">
          <P ta="center" fg="purple">
            <FontAwesomeIcon className="fa-4x" icon={['far', 'calendar']} />
          </P>
          <Stack gap="m">
            <Heading fz="xl">Schedule</Heading>
            <Stack gap="s">
              <P fz="m" fw="bold">
                Thursday June 18, 2020
              </P>
              <P fz="m" fw="bold">
                11:00 – 19:00 GMT
              </P>
            </Stack>
            {
              /* only show adjusted timezone if non GMT */ timeZone !== 'UTC' && tzName !== 'GMT' && (
                <Stack gap="s">
                  <P fz="m" fw="bold" fg="electric">
                    {tzHour}:00 – {parseInt(tzHour) + 8}:00 {tzName}
                  </P>
                  <P fz="s" fg="electric">
                    (your timezone has been auto-detected as {timeZone})
                  </P>
                </Stack>
              )
            }
          </Stack>
        </Stack>
      </Section>
      <div>
        <Flow gapY={20} gapX={40} bg="haiti" py={20} px={20} ai="center" jc="center" f="mono-2xs">
          {Object.keys(themeColors).map((themeName, i) => (
            <Div key={i} style={{ display: 'flex', alignContent: 'center' }}>
              <Div w={20} h={20} bg={themeColors[themeName]} />
              <Div mt={2} ml={10}>
                {themeName.slice(4)}
              </Div>
            </Div>
          ))}
        </Flow>
        <Outer mt={20}>
          <TimelineEl f="mono-2xs">
            {times(33).map((n) => {
              const hour = 11 + Math.floor(n / 4);
              const minute = padEnd((n % 4) * 15, 2, '0');
              return <Marker key={n} {...{ hour, minute, timeZone }} />;
            })}
          </TimelineEl>
          <ScheduleEl>
            {events.map((event, i) => (
              <Event key={i} {...event} timeZone={timeZone} />
            ))}
          </ScheduleEl>
        </Outer>
      </div>
    </Stack>
  );
}

export default function Page() {
  const pageData = useStaticQuery(graphql`
    query {
      sessions: allAirtable(filter: { table: { eq: "Sessions" } }) {
        nodes {
          data {
            Session_Name
            Scheduled(locale: "de")
            Duration
            Description
            Length_to_budget_in_agenda
            Format
            Theme
            Speakers {
              data {
                Name
              }
            }
          }
        }
      }
    }
  `);
  const sessions = pageData.sessions.nodes
    .filter(({ data }) => data.Scheduled)
    .map(({ data }) => {
      const date = new Date(data.Scheduled);
      const { time, hour, minute } = {
        time: date.getTime(),
        hour: date.getUTCHours(),
        minute: date.getUTCMinutes(),
      };
      const row_start = Math.round((hour + minute / 60) * 4) - 44 + 1;
      const row_span = Math.round(data.Duration / 15);
      // const col_start = data.Theme.slice(0, 1);
      // console.table({ row_start, row_span, col_start, duration: data.Duration });
      return {
        ...data,
        time,
        hour,
        minute,
        row_start,
        row_span,
        // col_start,
      };
    });

  const sortedSessions = sortBy(sessions, 'Theme');
  /*
    PAGE

  */
  return (
    <Layout bg="pearl" headerSlot={null} mainSlot={<Schedule events={sortedSessions} />} footerSlot={null} />
  );
}
