import React from 'react';
import { Helmet } from 'react-helmet';
import { ThemeProvider, createGlobalStyle } from 'styled-components';
import modernNormalize from 'styled-modern-normalize';

import SEO from 'src/components/legacy/SEO';
import { theme, fontStyles } from 'src/styles';
import { DebugStyles } from 'src/components/abstract/debug';
import { parseTheme } from 'src/components/abstract/system';
import { rootStyles } from 'src/components/abstract/root';
import { baseStyles as stackBaseStyles } from 'src/components/abstract/stacks';
import { baseStyles as gridBaseStyles } from 'src/components/abstract/grids';
import { baseStyles as textBaseStyles } from 'src/components/abstract/texts';

const GlobalStyle = createGlobalStyle`
  ${modernNormalize}
  ${fontStyles}
  ${rootStyles}
  ${stackBaseStyles}
  ${gridBaseStyles}
  ${textBaseStyles}

  html {
    font-size: 100%;
  }

  body {
    font-family: Graphik, 'Helvetica Neue', sans-serif;
    font-feature-settings: 'liga', 'kern';
    background-color: black;
    color: ${({ theme }) => theme.baseFontColor};
    color: white;
  }
`;

/*
  NB: difference between wrapRoot and wrapPage:
  https://markoskon.com/wrap-root-element-vs-wrap-page-element/
 */

export const wrapRootElement = ({ element }) => (
  <ThemeProvider theme={parseTheme(theme)}>
    {process.env.NODE_ENV === 'development' && <DebugStyles />}
    {element}
  </ThemeProvider>
);

export const wrapPageElement = ({ element, props }) => (
  <>
    <GlobalStyle />
    <SEO image={{ src: '/images/og-image.png', width: 1200, height: 630 }} />
    {element}
  </>
);

export const onRouteUpdate = ({ location, prevLocation }) => {
  if (process.env.NODE_ENV === `production` && location && prevLocation) {
    setTimeout(() => {
      const { title: pageTitle } = Helmet.peek();

      const pageUrl = location ? location.pathname + location.search + location.hash : undefined;

      window.dataLayer.push({
        event: 'gatsby-route-change',
        pageTitle,
        pageUrl,
      });
    }, 50);
  }

  return null;
};
