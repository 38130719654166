import React, { forwardRef } from 'react';
import styled, { css } from 'styled-components';

import { outputFunctions } from './system';
import { Box as Div } from './boxes';

export const baseStyles = css`
  .StackEl-base {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding-top: 0.1px;
    padding-bottom: 0.1px;
    & > * {
      width: stretch;
      margin-top: 0;
      margin-bottom: 0;
    }
    & > * + * {
    }
  }

  .StackEl-base,
  .PlainEl-base {
    padding-top: 0.1px;
    padding-bottom: 0.1px;
    & > * {
      margin-top: 0;
      margin-bottom: 0;
    }
    & > * + * {
      /* margin-top: calc(var(--stack-gap) - var(--stack-gap-adjust, 0px)); */
      margin-top: calc(var(--row-gap) - var(--stack-gap-adjust, 0px));
    }
  }

  .StackEl-base {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    & > * {
      flex: 0 0 auto;
      /* height: auto; */
      width: stretch;
    }
  }

  .ChainEl-base {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    & > * {
      flex: 0 0 auto;
      /* width: max-content; */
      margin-right: 0;
      margin-left: 0;
    }
    & > * + * {
      /* margin-left: var(--chain-gap); */
      margin-left: var(--column-gap);
    }
  }

  /* TEMPORARY GLOBAL CSS UNTIL PLAIN STYLES ARE READY!! */
  .StackEl-base {
    a {
      text-decoration: underline;
    }
  }
`;

/* eslint-disable prettier/prettier */

export const StackEl = styled(Div).attrs({
    className: 'StackEl-base',
  }).withConfig({
    shouldForwardProp: (key) => !['gapY','gap'].includes(key),
  })`${({ gap, gapY = gap }) => gapY && css`
    & > * { ${outputFunctions.gapY(gapY)} }
  `}`;

export const PlainEl = styled(Div).attrs({
    className: 'PlainEl-base',
  }).withConfig({
    shouldForwardProp: (key) => !['gapY','gap'].includes(key),
  })`${({ gap, gapY = gap }) => gapY && css`
    & > * { ${outputFunctions.gapY(gapY)} }
  `}`;

export const ChainEl = styled(Div).attrs({
    className: 'ChainEl-base',
  }).withConfig({
    shouldForwardProp: (key) => !['gapX','gap'].includes(key),
  })`${({ gap, gapX = gap }) => gapX && css`
    & > * { --chain-gap: ${gapX}px; }
  `}`;

/* eslint-enable prettier/prettier */

export const Chain = forwardRef(({ as, ...restProps }, ref) => (
  <ChainEl ref={ref} forwardedAs={as} {...restProps} />
));
Chain.displayName = 'Chain';

export const Stack = forwardRef(({ as, ...restProps }, ref) => (
  <StackEl ref={ref} forwardedAs={as} {...restProps} />
));
Stack.displayName = 'Stack';

export const Plain = forwardRef(({ as, ...restProps }, ref) => (
  <PlainEl ref={ref} forwardedAs={as} {...restProps} />
));
Plain.displayName = 'Plain';
