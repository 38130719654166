/* eslint-disable @typescript-eslint/no-use-before-define */
import React from 'react';
import styled from 'styled-components';
import { graphql, useStaticQuery } from 'gatsby';
import Image from 'gatsby-image';

// import Layout from 'src/layouts/bare';
import { Ph, Dump } from 'src/components/abstract/utils';
import { Stack, Chain } from 'src/components/abstract/stacks';
import { FlowGrid } from 'src/components/abstract/grids';
import { P, Ul, Li, Heading } from 'src/components/abstract/texts';
import { Box as Div, Section, Span, Aspect } from 'src/components/abstract/boxes';
import { FontAwesomeIcon } from 'src/components/vector/Icon';
import Layout from 'src/layouts/test';
// Legacy components
import { Button, ButtonLink, GhostButtonLink } from 'src/components/legacy/Button';
// SVG Components
import signPostHeroUrl from 'src/components/vector/sign-post-hero.svg';
import aToBMapUrl from 'src/components/vector/a-to-b-map.svg';
import navigatorUrl from 'src/components/vector/navigator.svg';

function sortByKey(key) {
  return function (a, b) {
    const [termA, termB] = [a[key]?.toLowerCase() || '', b[key]?.toLowerCase() || ''];
    return termA < termB ? -1 : termA > termB ? 1 : 0;
  };
}

const NoBr = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
`;

const HR = styled(Div)`
  height: 0;
  border-bottom: 2px solid #e42ebb;
  width: 67% !important;
`;

const Avatar = styled(Image)`
  width: 67% !important;
  border-radius: 50%;
`;

const AvatarPh = styled(Ph)`
  width: 67% !important;
  border-radius: 50%;
  overflow: hidden;
`;

const BulletList = styled(Stack).attrs({ forwardedAs: 'ul' })`
  & > li {
    position: relative;
    margin-left: 1.75em !important;
    &::before {
      content: '';
      display: block;
      position: absolute;
      left: -1.75em;
      top: var(--trim-capline);
      width: 1em;
      height: 1em;
      background-image: url('data:image/svg+xml;base64,PHN2ZyBmaWxsPSJub25lIiBoZWlnaHQ9IjE1IiB2aWV3Qm94PSIwIDAgMTggMTUiIHdpZHRoPSIxOCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBjbGlwLXJ1bGU9ImV2ZW5vZGQiIGQ9Im0xMS4yMjQ2IDBoLTExLjIyNDZ2MTQuNzY5MmgxMS4yMjQ2bDYuMDU1NC03Ljc5NDg0eiIgZmlsbD0iIzRmMTllYyIgZmlsbC1ydWxlPSJldmVub2RkIi8+PC9zdmc+');
      background-position: center center;
      background-size: contain;
      background-repeat: no-repeat;
    }
  }
`;

const SpeakerSection = styled(Section)`
  position: relative;
  z-index: 0;
  margin-top: 50px;
  margin-bottom: 50px;
  &::before,
  &::after {
    content: '';
    display: block;
    background: inherit;
    position: absolute;
    height: 200px;
    width: 100%;
    left: 0;
    transform: skewY(-3deg);
    z-index: -1;
  }
  &::before {
    top: 0;
    transform-origin: 0%;
  }
  &::after {
    bottom: 0;
    transform-origin: 100%;
  }
`;

/*
  NOTE re ASPECT RATIO
  - using `maxWidth` and `maxHeight` together is an undocumented hack
    but it appears incidentally in the gatsby docs here:
    https://www.gatsbyjs.org/docs/working-with-images/#using-fragments-to-standardize-formatting

  NOTE re cropFocus
  - the options are ENTROPY, ATTENTION and CENTER
 */

const Pill = styled(Span)`
  display: inline-block;
  border-radius: 50vmin;
  padding: 0.45em 1.2em;
  background: white;
  color: black;
`;

export default function Page() {
  const pageData = useStaticQuery(graphql`
    query {
      speakers: allAirtable(filter: { table: { eq: "Speakers" } }) {
        nodes {
          data {
            Company
            Twitter
            Link_to_headshot
            Speaker_s_Title
            Name
            Bio
            LinkedIn
            Filename
            # GitHub
            # Sessions {
            #   data {
            #     Name
            #     Session_Name
            #     Agenda
            #     Themes
            #   }
            # }
            Preferred_Pronouns
          }
        }
      }
      speakerImages: allFile(filter: { relativePath: { glob: "speakers/*" } }) {
        nodes {
          relativePath
          childImageSharp {
            fluid(maxWidth: 640, maxHeight: 640, cropFocus: CENTER) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  `);
  const speakers = pageData.speakers.nodes.map((node) => node.data);
  const speakerImages = pageData.speakerImages.nodes;
  /*
    PAGE

  */
  return (
    <Layout
      bg="pearl"
      headerSlot={
        <>
          <Stack gap="xxl" px="frame" py="xl" maxw="wrap-xl" mx="auto">
            <FlowGrid gapY="l">
              <p>
                <Pill f="mono-s" fw="bold">
                  JUNE 18, 2020 | ONLINE
                </Pill>
              </p>
              <Stack span={{ m: 6, ml: 7 }} order={{ _: 1, m: 0 }}>
                <Heading fz={{ _: 40, s: 50, ml: 70, l: 80 }}>
                  <NoBr>
                    The <Span fg="electric">Level 3</Span>
                  </NoBr>
                  <span> </span>
                  <NoBr>AI Assistant</NoBr>
                  <span> </span>
                  <NoBr>Conference</NoBr>
                  <span> </span>
                </Heading>
                <Stack gap="l">
                  <P fz="m" fw="bold">
                    A completely new, completely online event for the conversational AI community.
                  </P>
                  <P fz="s">
                    L3-AI is a completely free, online-only event, for developers, conversational designers,
                    and conversational AI researchers. The conference centers on the research, tools, and
                    services needed to build truly interactive AI assistants that are “Level 3”—that is, able
                    to provide real value to users through multi-turn, fully contextual conversations.
                  </P>
                </Stack>
                <Chain gap="m">
                  <ButtonLink>
                    Enter the event
                    <FontAwesomeIcon icon={['far', 'arrow-right']} style={{ marginLeft: 8 }} />
                  </ButtonLink>
                  <GhostButtonLink>Add to Calendar</GhostButtonLink>
                </Chain>
              </Stack>
              <Div maxw="100%" w={{ _: 400, m: 'span-6', ml: 'span-5' }}>
                <Aspect ratio={463 / 397}>
                  <img
                    src={signPostHeroUrl}
                    alt="A figure standing by a sign post, consulting their telephone"
                  />
                </Aspect>
              </Div>
            </FlowGrid>
            {/*

          SECTION

        */}
            <Section px="frame" maxw="wrap-xl" mx="auto">
              <Stack gap="l" ta="center" span={{ ml: 8 }} mx="auto">
                <P ta="center" fg="purple">
                  <FontAwesomeIcon className="fa-4x" icon={['far', 'calendar']} />
                </P>
                <Stack gap="m">
                  <Heading fz="l">Tune in from wherever you are</Heading>
                  <P fz="m" fw="bold">
                    Thursday June 18, 2020 <br />
                    11:00 – 19:00 GMT
                  </P>
                  <P fz="s">
                    The conversational AI community is global, and we&#39;ve arranged the L3-AI schedule to
                    allow people to tune in from as many locations and timezones as possible
                  </P>
                </Stack>
                <p>
                  <Button to="/schedule/">
                    View the Schedule
                    <FontAwesomeIcon icon={['far', 'arrow-right']} style={{ marginLeft: 8 }} />
                  </Button>
                </p>
              </Stack>
            </Section>
          </Stack>
        </>
      }
      mainSlot={
        <>
          <SpeakerSection py="xl" bg="haiti">
            <FlowGrid gapY="l" px="frame" maxw="wrap-xl" mx="auto">
              <Stack>
                <Heading ta="center" fz="l">
                  Featured Speakers
                </Heading>
                <FlowGrid>
                  {speakers
                    .sort(sortByKey('Company'))
                    .map(({ Name, Speaker_s_Title: Title, Company, Filename, Twitter, LinkedIn }, n) => {
                      const image = speakerImages.find(
                        (image) => image.relativePath === `speakers/${Filename}`,
                      );
                      return (
                        <Stack gap="s" span={{ _: 2, s: 4, ml: 3 }} key={n}>
                          {image ? <Avatar {...image.childImageSharp} /> : <AvatarPh txt="" bg="#333" />}
                          <Stack f="mono-xs" gap="xs">
                            <P f="sans-m" fw="bold">
                              {Name}
                            </P>
                            <HR bd="dazzle" />
                            {(Title || Company) && (
                              <Stack gap={10} fg="electric">
                                {Title && <P>{Title}</P>}
                                <P f="mono-s">{Company}</P>
                              </Stack>
                            )}
                            {Twitter ? (
                              <a target="_blank" rel="noreferrer" href={Twitter} style={{ color: 'white' }}>
                                <FontAwesomeIcon className="fa-lg" icon={['fab', 'twitter']} />
                              </a>
                            ) : LinkedIn ? (
                              <a target="_blank" rel="noreferrer" href={LinkedIn} style={{ color: 'white' }}>
                                <FontAwesomeIcon className="fa-lg" icon={['fab', 'linkedin']} />
                              </a>
                            ) : null}
                          </Stack>
                        </Stack>
                      );
                    })}
                </FlowGrid>
              </Stack>
            </FlowGrid>
          </SpeakerSection>
          {/*

        SECTION

      */}
          <Stack gap="xxl" pt="xl" pb="xxl">
            <Section px="frame" maxw="wrap-xl" mx="auto" f="sans-s">
              <FlowGrid gapY="l">
                <Stack
                  gap="l"
                  w={{ s: 'span-6', m: 'span-7' }}
                  ml={{ s: 'push-1', m: 0, ml: 'push-1' }}
                  f="sans-s"
                  order={{ _: 1, m: 0 }}
                >
                  <Stack gap="m">
                    <Heading fz="l">Interested in Speaking at L3-AI?</Heading>
                    <P>
                      We're looking for speakers! If you have something to share on the following topics - or
                      anything else you think is relevant to L3-AI - please complete our form.
                    </P>
                    <BulletList gap="s">
                      <Li>
                        Conversational AI is real - focus on how-to, real life examples, assistants in
                        practice
                      </Li>
                      <Li>Great conversational AI is not easy</Li>
                      <Li>Research, tools, and services needed to build truly contextual assistants</Li>
                      <Li>Lessons learned with shipping contextual assistants in production </Li>
                      <Li>How to leverage the latest research in ML and NLP</Li>
                    </BulletList>
                  </Stack>
                  <p>
                    <em>Our call for proposals has now closed</em>
                  </p>
                </Stack>
                <Div
                  w={{ _: 'span-2', s: 'span-3', m: 'span-5', ml: 'span-4' }}
                  ml={{ s: 'push-1', m: 0 }}
                  px={{ m: 50 }}
                >
                  <Aspect ratio={278 / 326}>
                    <img src={aToBMapUrl} alt="A map with lines on it tracing from point A to point B" />
                  </Aspect>
                </Div>
              </FlowGrid>
            </Section>
            <Section px="frame" maxw="wrap-xl" mx="auto" f="sans-s">
              <FlowGrid gapY="l">
                <Div w={{ _: 'span-3', s: 'span-4', m: 'span-5', ml: 'span-4' }} ml={{ s: 'push-1', m: 0 }}>
                  <Aspect ratio={246 / 310} ml={{ _: '5%', m: '15%' }}>
                    <img
                      src={navigatorUrl}
                      alt="A figure standing figure consulting a map, with a speech bubble above their head"
                    />
                  </Aspect>
                </Div>
                <Stack
                  gap="l"
                  w={{ s: 'span-6', m: 'span-7' }}
                  ml={{ s: 'push-1', m: 0, ml: 'push-1' }}
                  f="sans-s"
                >
                  <Stack gap="m">
                    <Heading fz="l">Who should attend</Heading>
                    <P>
                      Everyone in the conversational AI ecosystem with an interest in building better
                      (contextual, Level 3, multi-turn, etc.) AI assistants:
                    </P>
                    <BulletList gap="s">
                      <Li>Developers (enterprise and hobbyists)</Li>
                      <Li>Conversational designers</Li>
                      <Li>Developer leads and managers</Li>
                      <Li>AI/Innovation leads</Li>
                      <Li>Providers of conversational AI technology</Li>
                    </BulletList>
                  </Stack>
                </Stack>
              </FlowGrid>
            </Section>
          </Stack>
          {/*

        SECTION

      */}
          {/* <Section px="frame" py={100} maxw="wrap-xl" mx="auto">
          <Stack gap="30" ta="center" span={8} mx="auto">
            <Heading fz='l'>Our Partners</Heading>
            <Ph h="100" bg="#333"></Ph>
            <P fz='s'>
              In addition to speakers, we’re welcoming companies participating in providing conversational AI
              to apply to join for a virtual expo hall. We’d like to feature products that are making great
              tools and services used to build Level 3 contextual assistants.{' '}
            </P>
          </Stack>
        </Section> */}
        </>
      }
      footerSlot={<></>}
    />
  );
}
