import { css, ThemeProps } from 'styled-components';

export const theme = {
  // LEGACY SYSTEM
  baseFontColor: 'white',
  baseHeaderColor: 'white',
  primaryColor: '#5a17ee',
  secondaryColor: '#E42EBB',
  smallFontSize: '0.875rem',
  smallContentWidth: '720px',
  mediumContentWidth: '960px',
  largeContentWidth: '1070px',
  xlargeContentWidth: '1260px',
  smallBreakpoint: '800px',
  mediumBreakpoint: '1140px',
  largeBreakpoint: '1440px',

  // MODERN SYSTEM
  colors: {
    pearl: '#0A0C2A',
    haiti: '#1E1935',
    electric: '#77F7FA',
    dazzle: '#E42EBB',
    purple: '#5A17EE',
  },
  fontSpecs: {
    sans: {
      fontFamily: 'Graphik, sans-serif',
      lineHeight: '(1.15278em + 4.86111px)',
      // letterSpacing: '0',
      trimSides: '(1px - 0.075em)',
      trimCapline: '0.15em',
      trimBaseline: '0.115em',
      trimAdjacent: '(1em/6)',
    },
    mono: {
      fontFamily: 'JetBrainsMono, monospace;',
      lineHeight: '1.4em',
      // letterSpacing: '0',
      trimSides: '0',
      trimCapline: '0em',
      trimBaseline: '0em',
      trimAdjacent: '(1em/8)',
    },
  },
  fontSizes: {
    sans: {
      '2xs': 14,
      xs: 16,
      s: { _: 16, s: 18 },
      m: { _: 18, xs: 20, m: 22 },
      l: { _: 30, m: 36 },
      xl: { _: 36, m: 45 },
    },
    mono: {
      '2xs': { _: 11, s: 12 },
      xs: { _: 13, s: 14 },
      s: { _: 14, s: 15 },
    },
  },
  debug: {
    overlayColor: 'rgba(200,200,200,0.2)',
  },
  breakpoints: {
    xs: 24,
    s: 32,
    m: 48,
    ml: 64,
    l: 80,
    xl: 96,
  },
  gridColumns: { _: 4, s: 8, m: 12 },
  spacings: {
    inner: {
      y: {
        xs: { _: 12, m: 18 },
        s: { _: 20, m: 25 },
        m: { _: 30, m: 35, l: 40 },
        l: { _: 40, m: 50, l: 60 },
        xl: { _: 50, m: 65, l: 80 },
        xxl: { _: 60, m: 80, l: 100 },
      },
      x: {
        xs: 10,
        s: { _: '0.75rem', s: '1rem' },
        m: { _: '1rem', s: '1.25rem', l: '1.5rem' },
        l: { _: '1.5rem', s: '2rem', l: '3rem', xl: '4rem' },
      },
    },
    outer: {
      top: { _: '2rem', l: '4rem' },
      bottom: { _: '2rem', l: '4rem' },
      right: { _: '1rem', xs: '2rem', s: '3rem', m: '5rem', l: '7rem' },
      left: { _: '1rem', xs: '2rem', s: '3rem', m: '5rem', l: '7rem' },
    },
  },
};

export type ThemeType = ThemeProps<typeof theme>;

export const fontStyles = css`
  @font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Regular-Web.woff2') format('woff2'),
      url('/fonts/Graphik-Regular-Web.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Medium-Web.woff2') format('woff2'),
      url('/fonts/Graphik-Medium-Web.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Graphik';
    src: url('/fonts/Graphik-Semibold-Web.woff2') format('woff2'),
      url('/fonts/Graphik-Semibold-Web.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  /* @font-face {
    font-family: 'JetBrainsMono';
    src: url('/fonts/JetBrainsMono-Regular.woff2') format('woff2'),
      url('/fonts/JetBrainsMono-Regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  } */

  @font-face {
    font-family: 'JetBrainsMono';
    src: url('/fonts/JetBrainsMono-Medium.woff2') format('woff2'),
      url('/fonts/JetBrainsMono-Medium.woff') format('woff');
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'JetBrainsMono';
    src: url('/fonts/JetBrainsMono-Bold.woff2') format('woff2'),
      url('/fonts/JetBrainsMono-Bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    font-display: swap;
  }
`;
