import React, { useEffect } from 'react';
import styled, { createGlobalStyle, css } from 'styled-components';

import { useLocalStorage } from 'src/hooks/use-local-storage';
import { media } from 'src/components/abstract/system';

const PhOuter = styled.div.withConfig({
  shouldForwardProp: (property) => !'bg,h,aspect'.split(',').includes(property),
})`
  ${({ bg, h, aspect }) => css`
    position: relative;
    background-color: ${bg || 'rgba(0,0,0,0.1)'};
    ${h ? 'height' : 'padding-bottom'}: ${h ? `${h}px` : `${100 / (aspect || 1)}%`};
  `}
`;

const PhInner = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PhText = styled.div`
  text-align: center;
`;

export const Ph = ({ bg, h, aspect, txt = 'placeholder', children, ...restProps }) => (
  <div {...restProps}>
    <PhOuter {...{ bg, h, aspect }}>
      <PhInner>{children ? children : <PhText>{txt}</PhText>}</PhInner>
    </PhOuter>
  </div>
);

export function Dump({ data }) {
  return (
    <pre style={{ whiteSpace: 'pre' }}>
      <code>{JSON.stringify(data, null, 2)}</code>
    </pre>
  );
}

/* eslint-disable prettier/prettier */

const DebugStyleBlock = createGlobalStyle`

  body.debug {
    &::before, &::after {
      display: block;
      position: fixed;
      bottom: 0;
      left: 0;
      z-index: 999;
    }
    &::before {
      ${({ theme: { debug, bpKeysAsc } }) => css`
          background-color: ${debug.overlayColor};
          padding: 1rem;
          ${bpKeysAsc.map(key => media(key, css`
            content: '${key}';
          `))}

        `
      }
    }
    &::after {
      content: '';
      top: 0;
      right: calc(var(--outer-right) - var(--outer-left));
      margin-right: auto;
      margin-left: auto;
      pointer-events: none;
      --outer-x: calc(var(--outer-right) + var(--outer-left));
      --repeat-width: calc(100% / var(--column-count));
      --column-width: calc(var(--repeat-width) - var(--inner-x));
      width: calc(100% - var(--outer-x));
      background-size: calc(100% + var(--inner-x)) 100%;
      ${({ theme: { debug, bpKeysDesc } }) => css`
        max-width: calc(var(--outer-width-${bpKeysDesc[0]}) - var(--outer-x));
        background-image: repeating-linear-gradient(
          to right,
          ${debug.overlayColor},
          ${debug.overlayColor} var(--column-width),
          transparent var(--column-width),
          transparent var(--repeat-width)
        );
      `}
    }
  }
`;

/* eslint-enable prettier/prettier */

export function DebugStyles() {
  const [debugActive, setDebugActive] = useLocalStorage('debugActive', false);
  useEffect(() => {
    if (
      (process.env.CONTEXT && process.env.CONTEXT !== 'production') ||
      process.env.NODE_ENV === 'development'
    ) {
      document.body.classList.toggle('debug', debugActive);
      document.body.addEventListener('keyup', function (event) {
        if (event.shiftKey == true && event.ctrlKey == true && (event.keyCode || event.which) == 68) {
          setDebugActive((active) => !active);
          document.body.classList.toggle('debug', debugActive);
        }
      });
    }
  }, [debugActive, setDebugActive]);
  return <DebugStyleBlock />;
}
