import React, { FunctionComponent } from 'react';
import styled from 'styled-components';

import 'destyle.css/destyle.css';
import 'utility-opentype/css/utility-opentype.css';

import { Box as Div, Section, Span, Aspect } from 'src/components/abstract/boxes';

const Page = styled(Div)`
  overflow: hidden;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  & > * {
    flex: 0 1 auto;
    width: stretch;
  }
`;

const Layout: FunctionComponent = ({ children }) => {
  return <Page bg="pearl">{children}</Page>;
};

export default Layout;
