import React from 'react';
import styled, { ThemeContext, css, useTheme } from 'styled-components';

import { assertUnit } from './system';
import { Box } from './boxes';

export const baseStyles = css`
  :root {
    ${({ theme: { fontSpecs } }) => {
      const fontAlias = Object.keys(fontSpecs)[0];
      const font = fontSpecs[fontAlias];
      return [
        `--font-family: ${font.fontFamily};`,
        font.lineHeight && `--line-height: ${assertUnit(font.lineHeight, 'em')};`,
        font.letterSpacing && `--letter-spacing: ${assertUnit(font.letterSpacing, 'em')};`,
        font.trimCapline && `--trim-capline: ${assertUnit(font.trimCapline, 'em')};`,
        font.trimBaseline && `--trim-baseline: ${assertUnit(font.trimBaseline, 'em')};`,
        font.trimSides && `--trim-sides: ${assertUnit(font.trimSides, 'em')};`,
        font.trimAdjacent && `--trim-adjacent: ${assertUnit(font.trimAdjacent, 'em')};`,
      ].filter((x) => x);
    }}
  }
  body,
  .font-node,
  .PlainEl-base > * {
    font-family: var(--font-family);
    font-size: calc(var(--font-size, 1rem)); /* 1 */
    line-height: calc(var(--line-height, 1.15em)); /* 1 */
    letter-spacing: calc(var(--letter-spacing, 0)); /* 1 */
  }

  .StackEl-base > .txt-base,
  .PlainEl-base > :not(div) {
    margin-right: calc(var(--trim-sides, 0));
    margin-left: calc(var(--trim-sides, 0));
    transform: translatey(calc((1em - var(--line-height, 1.25em)) / 2 - var(--trim-capline, 0em)));
    &::after {
      content: '';
      display: block;
      height: 0.1px;
      width: stretch;
      margin-top: calc(
        (1em - var(--line-height, 1.25em)) - var(--trim-capline, 0em) - var(--trim-baseline, 0em)
      );
    }
  }

  .txt-base + .txt-base,
  .PlainEl-base > :not(div) + :not(div) {
    --stack-gap-adjust: var(--trim-adjacent);
  }

  .PlainEl-base > :any(p, ul, ol) {
    /* plain text node styles */
    & + :any(p, ul, ol) {
      /* adjacent node styles */
    }
  }
`;

// export const Txt = React.forwardRef((props, ref) => (
//   <Box as="p" className="txt-base" ref={ref} {...props} />
// ));
// Txt.displayName = 'Txt';

export const Txt = styled(Box).attrs({ forwardedAs: 'p', className: 'txt-base' })``;

export const Heading = React.forwardRef((props, ref) => {
  const {
    current: { hLevel },
  } = useTheme(ThemeContext);
  return <Box as={`h${hLevel}`} className="txt-base" ref={ref} {...props} />;
});
Heading.displayName = 'Heading';

export const P = styled(Box).attrs({ forwardedAs: 'p', className: 'txt-base' })``;
export const Ul = styled(Box).attrs({ forwardedAs: 'ul', role: 'list', className: 'txt-base' })``;
export const Ol = styled(Box).attrs({ forwardedAs: 'ol', role: 'list', className: 'txt-base' })``;
export const Li = styled(Box).attrs({ forwardedAs: 'li', role: 'listitem', className: 'txt-base' })``;
export const Blockquote = styled(Box).attrs({ forwardedAs: 'blockquote', className: 'txt-base' })``;
